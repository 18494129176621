import { graphql } from 'gatsby';
import * as React from 'react';
import { Body, Col, Divisor, Grid, H1, LargeSeparator, Row, Separator } from '@web/atomic';
import { SEO } from '../components/legacy/mol.seo/seo.component';
import CompleteTimeLine from '../components/org.complete-timeline/complete-timeline.component';
import IndexLayout from '../components/org.layout/layout.component';
import { ProfileSelectionSection } from '../modules/home/components/profile-selection-section.component';
import { PageProps } from '../utils/local-types';
import { getCanonicalUrl } from '@web/utils/url';
import { BackButton } from '../components/atm.back-button/back-button.component';
import LargeSeparatorRow from '../components/atm.large-separator-row/large-separator-row.component';

const EvidenciasPage: React.FunctionComponent<PageProps> = (props) => {
  return (
    <IndexLayout {...props}>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Evidências científicas da TCC-i',
          description:
            'A TCC-i é eficaz para melhorar tanto a insônia primária como secundária (quando existem outras condições envolvidas → ansiedade, depressão, etc.). Um resumo das principais evidências científicas é apresentado.',
        }}
      />

      <Grid>
        <Row mb mt center="xs">
          <Col xs={12} md={7}>
            <BackButton />
          </Col>
        </Row>

        <Row mt mb center="xs">
          <Col xs={12} md={7}>
            <H1>O programa que funciona</H1>
            <Separator />
            <Divisor />
            <Separator />
          </Col>
        </Row>
        <Row mb center="xs">
          <Col xs={12} md={7}>
            <Body>
              A Vigilantes do Sono é o primeiro programa digital de Terapia Cognitivo-Comportamental para insônia (TCC-i) para pessoas com
              insônia crônica no Brasil.
            </Body>
            <Body>
              A TCC-i é considerada hoje pelos sistemas nacionais de saúde dos Estados Unidos, Inglaterra e União Européia como a melhor
              solução para a insônia, com evidências que se estendem por mais de 30 anos. Recentemente a Associação Brasileira do Sono (ABS)
              também definiu a TCC-i como padrão ouro para a melhoria da insônia.
            </Body>
            <Body>
              A TCC-i é eficaz para melhorar tanto a insônia primária como quando existem outras condições envolvidas - ansiedade,
              depressão, dores crônicas, etc. Um resumo das principais evidências científicas é apresentado abaixo.
            </Body>
            <Body>
              Embora se reconheça que a TCC-i funciona, a falta de terapeutas e especialistas em sono evita a ampla divulgação desse tipo de
              terapia. O programa digital da Vigilantes do Sono é uma alternativa ao modelo presencial e a prescrições de remédios para
              dormir.
            </Body>
            <LargeSeparator />
          </Col>
        </Row>
      </Grid>
      <CompleteTimeLine items={data} />
      <Grid>
        <Row>
          <Col xs={12}>
            <BackButton />
          </Col>
        </Row>
        <LargeSeparatorRow />
      </Grid>
      <ProfileSelectionSection />
    </IndexLayout>
  );
};

export default EvidenciasPage;

export const query = graphql`
  query EvidencesQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

// TODO: read from graphql (gatsby source)
const data = [
  {
    year: 1994,
    title: 'A eficácia da TCC-i',
    contentHTML:
      '<p>A TCC para insônia (TCC-i) se mostra um programa eficaz e duradouro para cerca de 70% dos pacientes.</p> <h3 id="estudos">Estudos</h3> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/8037252">Nonpharmacological interventions for insomnia: a meta-analysis of treatment efficacy. Morin CM, Culbert JP, Schwartz SM. 1994.</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/17162986">Psychological and behavioural treatment of insomnia: update of evidence from 1998–2004. Morin et al. 2006.</a></p>',
  },
  {
    year: 2003,
    title: 'Remédios apresentam riscos a longo prazo',
    contentHTML:
      '<p>Apesar de amplamente utilizadas, as terapias farmacológicas tem resultados limitados para casos de insônia crônica e apresentam riscos de longo prazo, principalmente em populações de idosos.</p> <h3 id="estudos">Estudos</h3> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/14580077">Older adults and withdrawal from hypnotics. Curran et al. 2003</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/16860264">Management of chronic insomnia in elderly persons. Bain KT. 2006</a></p>',
  },
  {
    year: 2004,
    title: 'Resultados da TCC-i digital',
    contentHTML:
      '<p>Diversos estudos já avaliaram a TCC-i digital inclusive contrastando com tratamentos placebos e mostraram resultados promissores, mas com certa variedade entre eles.</p> <h3 id="estudos">Estudos</h3> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/14756620">Internet-based treatment for insomnia: a controlled evaluation. Stroöm et al. 2004.</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/19581560">Efficacy of an Internet-based behavioral intervention for adults with insomnia. Ritterband et al. 2009.</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3353040/">A randomised placebo-control trial of online CBTi. Espie et al. 2012.</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/22585048">Computerised CBTi: a systematic review and meta-analysis. Cheng &amp; Dizon et al. 2012.</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3962311/">Online CBTi – targeted screening and interactive web design. Anderson, et al 2014.</a></p>',
  },
  {
    year: 2009,
    title: 'TCC-i apresenta resultados melhores que remédios',
    contentHTML: `<p>
        Pacientes que realizaram a TCC-i tiveram melhores resultados no longo-prazo se comparados a pacientes que fizeram tratamento com remédios. O tratamento em conjunto (TCC-i + remédios) apresenta resultados ainda melhores que as opções individuais.
      </p>
      <h3 id="estudos">Estudo</h3>
      <p>
        <a href="https://jamanetwork.com/journals/jama/fullarticle/183931">
        Cognitive Behavioral Therapy, Singly and Combined With Medication, for Persistent Insomnia. Morin et al. 2009.
        </a>
      </p>
      `,
  },
  {
    year: 2011,
    title: 'Mais resultados positivos da TCC-i',
    contentHTML:
      '<p>A eficácia da TCC-i para melhorar os resultados em pacientes com insônia primária e secundária tem sido repetidamente demonstrada, com melhorias na saúde mental e física.</p> <h3 id="estudos">Estudos</h3> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/20369395">Impact of CBTi on concomitant anxiety. Belleville et al. 2011.</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/22162648">CBT for insomnia co-morbid with COPD. Kapella et al. 2011.</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/22982083">CBT interventions for co-morbid chronic pain &amp; insomnia. Pigeon et al. 2012.</a></p>',
  },
  {
    year: 2016,
    title: 'Mesmo com a presença de comorbidades, TCC-i traz benefícios',
    contentHTML:
      '<p>Vários estudos clínicos mostram que a compreensão e intervenção de insônia por meio de TCC-i podem ter grandes benefícios para a saúde mental mesmo com a presença de comorbidades. </p> <p>No maior estudo clínico randomizado de todos os tempos, indivíduos que receberam TCC-i online mostraram redução na insônia, além de melhorias na depressão, ansiedade e bem-estar psicológico.</p> <h3 id="estudos">Estudos</h3> <p><a href="https://www.cambridge.org/core/journals/behavioural-and-cognitive-psychotherapy/article/treating-depression-and-anxiety-with-digital-cognitive-behavioural-therapy-for-insomnia-a-real-world-nhs-evaluation-using-standardized-outcome-measures/4EF2336A8B342B4791A01EB7A80EE7D7">Treating depression and anxiety with digital CBTi: a real world NHS evaluation using standardized outcome measures. Luik et al. 2016</a></p> <p><a href="https://www.ncbi.nlm.nih.gov/pubmed/28332858">Modified CBTi in depressed adolescents: A pilot study. Conroy et al. 2017.</a></p> <p><a href="https://www.thelancet.com/journals/lanpsy/article/PIIS2215-0366(17)30328-0/fulltext">The effects of improving sleep on mental health (OASIS): a randomised controlled trial with mediation analysis. Freeman et al. 2017.</a></p>',
  },
];
