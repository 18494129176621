import { Body, H2, Separator } from '@web/atomic';
import * as React from 'react';
import { TimelineEventCardStyled, TimelineEventCardTextSeparator, TimelineEventCardTextWrapper } from './timeline-event-card.style';

export interface TimelineEventCardProps {
  title: string;
  contentHTML: string;
}

// eslint-disable-next-line react/display-name
const TimelineEventCard: React.FunctionComponent<TimelineEventCardProps> = React.memo((props) => (
  <TimelineEventCardStyled>
    <TimelineEventCardTextWrapper>
      <H2 cell>{props.title}</H2>
      <Separator />
      <Body>
        <div dangerouslySetInnerHTML={{ __html: props.contentHTML }} />
      </Body>
      <TimelineEventCardTextSeparator />
    </TimelineEventCardTextWrapper>
  </TimelineEventCardStyled>
));

export default TimelineEventCard;
