import { GrayColor, LightColor, Spacing, TransitionDuration } from '@web/atomic';
import { onlyDesktopStyle } from '@web/atomic/legacy/obj.mixin';
import styled from 'styled-components';

export const TimelineEventStyled = styled.div`
  position: relative;
  display: grid;
  grid-gap: ${Spacing.XXXLarge};
  grid-template-columns: [line] auto [year] auto [card] 1fr;
  @media all and (max-width: 48em) {
    grid-gap: ${Spacing.Large};
    grid-template-columns: auto;
  }
`;

export const TimelineEventLineWrapperStyled = styled.div`
  position: relative;
  ${onlyDesktopStyle}
`;
export const TimelineEventLineStyled = styled.div`
  height: 100%;
  width: 2px;
  background: ${LightColor.Primary};
`;
export interface TimelineEventBallStyledProps {
  highlight: boolean;
}
const ballSize = 11;
export const TimelineEventBallStyled = styled.div`
  position: absolute;
  top: 0;
  left: ${`-${ballSize / 2 - 1}px`};
  width: ${`${ballSize}px`};
  height: ${`${ballSize}px`};
  transform: scale(${(props: TimelineEventBallStyledProps) => (props.highlight ? 1.6 : 1)});
  border-radius: ${`${ballSize}px`};
  border: 2px solid ${LightColor.Primary};
  background: ${(props: TimelineEventBallStyledProps) => (props.highlight ? LightColor.Primary : GrayColor.White)};
  transition: transform ${TransitionDuration};
`;

export const TimelineEventYearStyled = styled.div``;
export const TimelineEventCardWrapperStyled = styled.div`
  margin-bottom: ${Spacing.XXLarge};
`;
