import styled from 'styled-components';
import { HorizontalScrollProps } from '.';
import { Spacing } from '../legacy/obj.constants';
import { GridSettings } from '../legacy/obj.grid';

export const HorizontalScrollStyled = styled.div`
  position: relative;

  display: flex;
  align-items: ${(props: HorizontalScrollProps) => props.alignItems};

  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props: HorizontalScrollProps) =>
    props.noGutter
      ? ''
      : `
    padding: 0 ${GridSettings.flexboxgrid.outerMargin}rem;
    ::after {
      content: '';
      flex: 0 0 ${GridSettings.flexboxgrid.outerMargin}rem;
    }
  `}

  > * {
    flex: 0 0 auto;
    margin-right: ${Spacing.Medium};
  }
`;
