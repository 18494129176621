import { HDisplayStyle } from '@web/atomic/legacy/atm.typography';
import { GrayColor, LightColor, FontFamily, FontSize, FontWeight, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const DecadeTimeLineStyled = styled.ol`
  background: ${GrayColor.White};
  margin: 0;
  padding: 0;

  border-bottom: 1px solid ${GrayColor.Gray};
  margin-top: 0;
  @media all and (min-width: 48em) {
    margin-top: ${Spacing.Medium};
    border-bottom: 0;
  }
`;
export interface DecadeTimeLineItemStyledProps {
  selected: boolean;
}

export const DecadeTimeLineItemStyled = styled.li`
  list-style: none;

  margin-top: ${Spacing.Medium};
  margin-bottom: 0;
  @media all and (min-width: 48em) {
    margin-top: 0;
    margin-bottom: ${Spacing.Medium};
  }
`;

export const DecadeTimeLineItemTextStyled = styled.span`

  ${(props: DecadeTimeLineItemStyledProps) =>
    props.selected
      ? HDisplayStyle
      : `
  color: ${GrayColor.GrayXDark};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Normal};
  margin: 0;
  line-height: 1.5;
  font-size: ${FontSize.Large};
  `}
  color: ${(props: DecadeTimeLineItemStyledProps) => (props.selected ? GrayColor.GrayXDark : GrayColor.Gray)};
  cursor: pointer;
  :hover {
    color: ${GrayColor.GrayXDark};
  }
`;
