import { GrayColor, LightColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const TimelineEventCardStyled = styled.div`
  position: relative;
  margin-top: 32px;
`;

export const TimelineEventCardTextWrapper = styled.div`
  background-color: ${GrayColor.GrayXXLight};
  padding: ${Spacing.Large} ${Spacing.XLarge};
`;

export const TimelineEventCardTextSeparator = styled.div`
  margin-top: ${Spacing.XLarge};
`;
