import { MatchMedia } from '@web/atomic/legacy/obj.match-media';
import { HorizontalScroll } from '@web/atomic/obj.horizontal-scroll';
import { hasDocument, hasWindow } from '@web/utils/platform';
import * as React from 'react';
import { DecadeTimeLineItemStyled, DecadeTimeLineItemTextStyled, DecadeTimeLineStyled } from './decade-timeline.style';

interface DecadeTimeLineProps {
  years: number[];
  selectedYear: number;

  /**
   * @param year: is an array of numbers inside the clicked decade
   */
  onDecadeClick: (year: number[]) => void;
}
const areEqualProps = (prevProps: DecadeTimeLineProps, nextProps: DecadeTimeLineProps) => {
  if (prevProps.selectedYear !== nextProps.selectedYear) {
    return false;
  }

  return areEqualArrays(prevProps.years, nextProps.years);
};
const areEqualArrays = (arr1: number[], arr2: number[]) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = arr1.length; (i = i - 1); ) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export interface DecadeTimeLineRef {
  /**
   * @param year it will scroll to the related year's decade
   */
  scrollToDecade: (year: string | number) => void;
}

/**
 * it receives an year array and draws a list of decades
 */
// eslint-disable-next-line react/display-name
const DecadeTimeLine = React.memo(
  React.forwardRef((props: DecadeTimeLineProps, ref: React.Ref<DecadeTimeLineRef>) => {
    const decadeToYearsDic = React.useMemo(() => getDecadeToYearsDic(props.years), [props.years]);
    const selectedDecade = getDecadeFromYear(props.selectedYear);

    const handleClickHOF = (decade: string) => () => {
      props.onDecadeClick(decadeToYearsDic[decade]);
    };

    const innerRef = useScrollToRef(ref);
    const items = Object.keys(decadeToYearsDic).map((decade) => (
      <DecadeTimeLineItem decade={decade} onClick={handleClickHOF(decade)} selected={decade === String(selectedDecade)} key={decade} />
    ));

    return (
      <DecadeTimeLineStyled>
        <MatchMedia defaultMinWidth={`48rem`}>
          {(md) =>
            md ? (
              <HorizontalScroll ref={innerRef} noGutter alignItems={'baseline'}>
                {items}
              </HorizontalScroll>
            ) : (
              <>{items}</>
            )
          }
        </MatchMedia>
      </DecadeTimeLineStyled>
    );
  }),
  areEqualProps
);

const getDecadeToYearsDic = (years: number[]) =>
  years.reduce((acc, curr) => {
    const decade = getDecadeFromYear(curr);
    if (acc[decade]) {
      acc[decade].push(curr);
    } else {
      acc[decade] = [curr];
    }
    return acc;
  }, {});
const getDecadeFromYear = (year: number) => Math.floor(year / 10) * 10;

const useScrollToRef = (ref: React.Ref<DecadeTimeLineRef>) => {
  const innerRef = React.useRef<any>();
  React.useImperativeHandle(ref, () => ({
    scrollToDecade: (year: string) => {
      if (!hasWindow() || !hasDocument() || !innerRef.current) {
        return;
      }
      const scrollToEl = document.getElementById(getIdForDecade(getDecadeFromYear(Number(year))));
      const left = scrollToEl.offsetLeft;
      // https://stackoverflow.com/a/55221484/3670829
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
      if (!innerRef || !innerRef.current || !innerRef.current.scrollTo) {
        return;
      }
      if (supportsNativeSmoothScroll) {
        innerRef.current.scrollTo({ left, behavior: 'smooth' });
      } else {
        innerRef.current.scrollTo(left, 0);
      }
    },
  }));

  return innerRef;
};

const getIdForDecade = (decade: string | number) => {
  return `DecadeTimeLine-${decade}`;
};

//////////////////////////////////////////////////
interface DecadeTimeLineItemProps {
  decade: string;
  selected: boolean;
  onClick: () => void;
}
const areEqualItemProps = (prevProps: DecadeTimeLineItemProps, nextProps: DecadeTimeLineItemProps) => {
  return prevProps.decade === nextProps.decade && prevProps.selected === nextProps.selected;
};

// eslint-disable-next-line react/display-name
const DecadeTimeLineItem: React.FunctionComponent<DecadeTimeLineItemProps> = React.memo((props) => {
  return (
    <DecadeTimeLineItemStyled id={getIdForDecade(props.decade)} key={props.decade} onClick={props.onClick}>
      <DecadeTimeLineItemTextStyled selected={props.selected}>{props.decade}</DecadeTimeLineItemTextStyled>
    </DecadeTimeLineItemStyled>
  );
}, areEqualItemProps);

export default DecadeTimeLine;
